<template>
  <div class="py-4">
    <TableCustomer
      ref="table-customer"
      :paging="paging"
      :data="customers"
      type="NEW"
      @sort="sortBy"
      @schedule="schedule"
      @create-account="createAccount"
      @active-package="activePackage"
      @delete="deleteCustomer"
    />

    <ModalBook
      :type-form="typeForm"
      :customer="customer"
      isInputInterview
      @save="createSchedule"
      @hide="resetModal"
    />
    <ModalCreateAccount
      v-if="showModalAccount"
      :show="showModalAccount"
      :selected-items="selectedCustomers"
      :customer="customer"
      @reset="resetModal"
      @create-success="createSuccess"
    />
    <ModalActivePackage
      :selected-items="selectedCustomers"
      :customer="customer"
      @reset="resetModal"
      @active-success="activeSuccess"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_MODAL_ASSIGN,
  SET_MODAL_BOOK,
} from '@/core/services/store/customer.module';
import { MODAL_TYPE } from '@/core/plugins/constants';
import {
  CREATE_CALENDER,
  SET_LOADING,
} from '@/core/services/store/calender.module';
const { mapState, mapMutations } = createNamespacedHelpers('customer');
const { mapActions: calendarActions, mapMutations: calendarMutations } =
  createNamespacedHelpers('calender');
export default {
  name: 'NewCustomer',
  components: {
    TableCustomer: () => import('./TableCustomer.vue'),
    ModalBook: () => import('@/view/pages/calendar/CalendarScheduleForm.vue'),
    ModalCreateAccount: () => import('./ModalCreateAccount.vue'),
    ModalActivePackage: () => import('./ModalActivePackage.vue'),
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      typeForm: MODAL_TYPE.CREATE,
      selectedCustomers: [],
      customer: null,
      showModalAccount: false,
    };
  },
  computed: {
    ...mapState(['showModalAssign', 'showModalBook']),
  },
  watch: {
    'paging.page'(val) {
      this.$emit('page', val);
    },
    'paging.pageSize'(val) {
      this.$emit('size', val);
    },
    total(val) {
      val && (this.paging.total = val);
    },
  },
  methods: {
    ...mapMutations({ SET_MODAL_ASSIGN, SET_MODAL_BOOK }),
    ...calendarActions({ CREATE_CALENDER }),
    ...calendarMutations({ SET_LOADING }),
    async schedule(customer) {
      this.customer = customer;
      this.$bvModal.show('calendar-schedule-add-modal');
    },
    async createSchedule({ data }) {
      try {
        this.SET_LOADING(true);
        const res = await this.CREATE_CALENDER(data);

        if (!res.data)
          return this.$toastr.e({
            title: 'Lỗi!',
            msg: 'Tạo mới không lịch hẹn thành công',
          });
        await this.$api.post('/PackageAccountTransaction/BookCalender', {
          bookingId: res.data.id,
          id: this.customer.id,
        });
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Tạo mới lịch hẹn thành công',
        });
        this.$bvModal.hide('calendar-schedule-add-modal');
        this.$emit('reload-data');
      } catch (error) {
        console.log(error);
      } finally {
        this.SET_LOADING(false);
      }
    },
    sortBy(val) {
      this.$emit('sort', val);
    },
    createAccount(params) {
      this.selectedCustomers = params.selectedCustomers;
      this.customer = params.customer;
      this.showModalAccount = true;
    },
    activePackage(params) {
      this.selectedCustomers = params.selectedCustomers;
      this.customer = params.customer;
      this.$bvModal.show('modal-active-package');
    },
    resetModal() {
      this.selectedCustomers = [];
      this.customer = null;
      this.showModalAccount = false;
    },
    async deleteCustomer(customers) {
      try {
        this.$store.commit('context/setLoading', true);
        const payload = {
          ids: customers.map((el) => el.id),
        };
        const { meta, error } = await this.$api.post(
          `/PackageAccountTransaction/Input/delete`,
          payload,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.$store.commit('context/setLoading', false);
        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
        this.$store.commit('context/setLoading', false);
      }
    },
    activeSuccess() {
      this.$refs['table-customer'].resetSelect();
      this.$emit('reload-data');
    },
    createSuccess() {
      this.$refs['table-customer'].resetSelect();
      this.$emit('reload-data');
    },
  },
};
</script>
<style lang=""></style>
